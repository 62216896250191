import React from 'react';
import { Field, Form } from 'react-final-form';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from 'reactstrap';
import renderRadioButtonField from '~~/form/RadioButton';
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
} from '@/shared/components/form/FormElements';
import { colorAdditional } from '~/utils/palette';

const CartPurchase = () => (
  <Form onSubmit={() => {}} initialValues={{ delivery: 'russian_post' }}>
    {({ handleSubmit }) => (
      <CartDeliveriesForm onSubmit={handleSubmit}>
        <CartTotal>Total Price: $348.00</CartTotal>
        <FormButtonToolbar>
          <Button as={Link} variant="primary" to="/e-commerce/payment">Purchase</Button>
        </FormButtonToolbar>
      </CartDeliveriesForm>
    )}
  </Form>
);

export default CartPurchase;

// region STYLES

const CartDeliveriesForm = styled(FormContainer)`
  margin-top: 20px;
`;

const CartDelivery = styled.div`
  margin-right: 50px;
  margin-bottom: 10px;

  &:last-child {
    margin-right: 0;
  }

  & > label {
    margin-bottom: 0;
  }
  
`;

const CartDeliveryField = styled(FormGroupField)`
  flex-wrap: wrap;
`;

const CartTotal = styled.h4`
  width: 100%;
  font-weight: 700;
  margin-bottom: 5px;
`;

const CartDeliveryPrice = styled.p`
  font-size: 10px;
  line-height: 13px;
  margin: 0;
  padding-left: 27px;
`;

const CartDeliveryTime = styled(CartDeliveryPrice)`
  color: ${colorAdditional};
  margin-bottom: 8px;
`;

// endregion
