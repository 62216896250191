import React, { useState, useEffect } from 'react';
import { Col, Button, Card, CardBody } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import HeartIcon from 'mdi-react/HeartIcon';
import StarIcon from 'mdi-react/StarIcon';
import StarOutlineIcon from 'mdi-react/StarOutlineIcon';
import { Link, useParams } from 'react-router-dom';
import produce from 'immer';
import Cookies from 'js-cookie';
import {
  colorAdditional,
  colorBlue,
  colorBlueHover,
  colorYellow,
} from '~/utils/palette';
import ProductGallery from './ProductGallery';
import ProductTabs from './ProductTabs';
import images from './imgs';
import { apiCallBegan } from '~/store/api';
import { readProductApi, getThumbnailsApi } from '~/apiCalls/marketplaceApi';
import { getProductInfo, marketplaceError, getThumbnails } from '~/store/marketplace';
import { addedToCart } from '~/store/cart';


const ProductCard = () => {
  const [color, setColor] = useState('white');
  const { product_id } = useParams();
  const dispatch = useDispatch();
  const { currentProductInfo, thumbnails, cart } = useSelector(state => state.marketplace);

  const addtoCart = () => {
    dispatch(addedToCart(product_id));
  };

  useEffect(() => {
    console.log(Cookies.get('cart'));
  }, [cart]);

  useEffect(() => {
    if (product_id) {
      dispatch(apiCallBegan({
        axiosCall: readProductApi,
        data: product_id,
        onSuccess: getProductInfo.type,
        onError: marketplaceError.type,
      }));
      dispatch(apiCallBegan({
        axiosCall: getThumbnailsApi,
        data: product_id,
        onSuccess: getThumbnails.type,
        onError: marketplaceError.type,
      }));
    }
  }, [product_id]);

  const onLike = () => {
    if (color === 'white') {
      setColor('#70bbfd');
    } else {
      setColor('white');
    }
  };

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <ProductCardContent>
            {thumbnails.length > 0 && (<ProductGallery images={thumbnails?.map((pt) => ({ src: `${process.env.REACT_APP_API_URL}assets/${pt.product_thumbnail_id}?fit=cover&width=438&height=438` }))} />)}
            <ProductCardInfo>
              <ProductCardTitle>{currentProductInfo.title}</ProductCardTitle>
              <ProductCardRate>
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarOutlineIcon />
                <ProductCardLink href="/easydev/e-commerce/product_page">
                  See all reviews
                </ProductCardLink>
              </ProductCardRate>
              <ProductCardPrice>
                ${currentProductInfo.price} <ProductCardOldPrice>${currentProductInfo.price}</ProductCardOldPrice>
              </ProductCardPrice>
              <p className="typography-message">
                {currentProductInfo.description}
              </p>
              <ProductCardForm>
                <Button as={Link} variant="primary" to="/e-commerce/cart" onClick={addtoCart}>Add to cart</Button>
                <ProductCArdWishButton
                  type="button"
                  onClick={onLike}
                >
                  <HeartIcon color={color} />Add to wishlist
                </ProductCArdWishButton>
              </ProductCardForm>
              <ProductTabs />
            </ProductCardInfo>
          </ProductCardContent>
        </CardBody>
      </Card>
    </Col>
  );
};

export default ProductCard;

// region STYLES

const ProductCardForm = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`;

const ProductCardLabel = styled.div`
  font-weight: 500;
`;

const ProductCardContent = styled.div`
  display: flex;
  flex-wrap: wrap;

  .product-card__btn-toolbar {
    margin-bottom: 10px;
  }
`;

const ProductCardInfo = styled.div`
  width: calc(100% - 440px);
  padding-left: 42px;

  @media screen and (max-width: 1199px) {
    width: 100%;
    padding-left: 0;
    padding-top: 35px;
  }
`;

const ProductCardTitle = styled.h3`
  text-align: left;
  font-weight: 700;
  margin-bottom: 10px;
`;

const ProductCardRate = styled.div`
  display: flex;
  margin-bottom: 30px;

  svg {
    fill: ${colorYellow};
    height: 14px;
    width: 14px;
  }
`;

const ProductCardLink = styled.a`
  font-size: 12px;
  color: ${colorBlue};
  line-height: 16px;
  display: block;
  margin-left: 5px;

  &:hover {
    color: ${colorBlueHover};
    text-decoration: none;
  }
`;

const ProductCardPrice = styled.h1`
  margin-bottom: 25px;
  text-align: left;
`;

const ProductCardOldPrice = styled.span`
  font-size: 24px;
  color: ${colorAdditional};
  text-decoration: line-through;
`;

const ProductCArdWishButton = styled.button`
  display: block;
  padding: 10px 0;
  font-size: 14px;
  color: ${colorBlue};
  height: 42px;
  background: transparent;
  border: none;
  cursor: pointer;
  transition: all 0.3s;

  svg {
    height: 14px;
    transition: all 0.3s;
    stroke: ${colorBlue};
  }

  &:hover {
    color: ${colorBlueHover};

    svg {
      fill: ${colorBlueHover};
    }
  }
`;

//
