import React from 'react';
import { Col, Card, CardBody, } from 'reactstrap';
import ProductEditForm from './ProductEditForm';
import { CardTitleWrap, CardTitle } from '~~/Card';

const PaymentCard = () => (
  <Col md={12} lg={12}>
    <Card>
      <CardBody>
        <CardTitleWrap>
          <CardTitle>Main Information</CardTitle>
        </CardTitleWrap>
        <ProductEditForm />
      </CardBody>
    </Card>
  </Col>
);

export default PaymentCard;
