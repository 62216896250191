import React from 'react';
import { Nav, TabContent, TabPane, NavItem, NavLink } from 'reactstrap';

const ProductTabs = () => (
  <div>
    <div defaultActiveKey="1">
      <div>
        <Nav className="nav-tabs">
          <NavItem>
            <NavLink eventKey="1">
              Description
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink eventKey="2">
              Delivery
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink eventKey="3">
              Refunds
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent className="typography-message">
          <TabPane eventKey="1">
            <p>Direction has strangers now believing. Respect enjoyed gay far exposed parlors towards. Enjoyment
              use tolerably dependent listening men. No peculiar in handsome together unlocked do by. Article
              concern joy anxious did picture sir her. Although desirous not recurred disposed off shy you
              numerous securing. Knowledge nay estimable questions repulsive daughters boy. Solicitude gay way
              unaffected expression for.
            </p>
            <p>His mistress ladyship required off horrible disposed rejoiced. Unpleasing pianoforte unreserved as
              oh he unpleasant no inquietude insipidity. Advantages can discretion possession add favourable
              cultivated admiration far.
            </p>
          </TabPane>
          <TabPane eventKey="2">
            <p>Direction has strangers now believing. Respect enjoyed gay far exposed parlors towards. Enjoyment
              use tolerably dependent listening men. No peculiar in handsome together unlocked do by.
            </p>
          </TabPane>
          <TabPane eventKey="3">
            <p>His mistress ladyship required off horrible disposed rejoiced. Unpleasing pianoforte unreserved as
              oh he unpleasant no inquietude insipidity. Advantages can discretion possession add favourable
              cultivated admiration far.
            </p>
          </TabPane>
        </TabContent>
      </div>
    </div>
  </div>
);

export default ProductTabs;
