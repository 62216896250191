import React from 'react';
import { Col, ButtonToolbar, Button, Table } from 'reactstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { DateTime } from "luxon";
import getTransText from '~~/get-text/getTransText';
import ReadRenderProductList from './ReadRenderProductList';
import {
  Card, CardBody, CardTitleWrap, CardTitle,
} from '~~/Card';

const ProductsListTable = ({ rows, handlePage }) => {
  const header = [
    { id: 0, title: getTransText('marketplace.product.date') },
    { id: 1, title: getTransText("marketplace.product.title") },
    { id: 2, title: getTransText("marketplace.product.description") },
    { id: 3, title: getTransText("marketplace.product.price") },
    { id: 4, title: getTransText("marketplace.product.status") },
    { id: 5, title: getTransText("productedit.header.edit") },
  ];

  const monthRender = DateTime.now().toFormat('yyyy-MM');

  const renderList = () => {
    if (rows?.data && rows?.data.length > 0) {
      return (rows.data.map((item, index) => (
        <ReadRenderProductList item={item} index={index} key={item.id} />
      )));
    }

    return (<td colSpan="4" className="transaction__notice">{getTransText("productsetting.productlist.no")}</td>);
  };

  const Pagination = () => {
    if (rows?.has_more) {
      const lastElement = rows.data.slice(-1);
      return (
        <div className="btn-toolbar">
          <button className="btn btn-primary" type="button" onClick={() => handlePage(lastElement[0].id)}>
            More Activity
          </button>
        </div>
      );
    }
  };

  return (
    <div className="wallet__activity">
      <h3 className="page-title">{getTransText("productsetting.productlist.title")}</h3>
      <p className="page-subhead">{getTransText("productsetting.productlist.subtitle")}</p>
      <Table responsive>
        <thead>
          <tr>
            {header.map(item => (
              <th key={item.id}>{item.title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {renderList()}
        </tbody>
      </Table>
      {Pagination(rows)}
    </div>
  );
};

ProductsListTable.propTypes = {
  productListTableData: PropTypes.shape({
    tableHeaderData: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string,
      name: PropTypes.string,
    })),
    tableRowsData: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
};

export default ProductsListTable;

// region STYLES

const ProductListToolbar = styled(ButtonToolbar)`
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;

  @media screen and (max-width: 992px) {
    position: relative;
    margin-top: 10px;
  }
`;

const ProductListAddButton = styled(Button)`
  padding: 4px 25px;
  text-transform: none;
`;

// endregion
