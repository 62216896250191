import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { Field, Form, FormSpy } from 'react-final-form';
import CurrencyUsdIcon from 'mdi-react/CurrencyUsdIcon';
import TagIcon from 'mdi-react/TagIcon';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from "react-router-dom";
import ReactQuill from 'react-quill';
import { produce } from 'immer';
import {
  Button,
  Col,
  Container,
  Row,
  ButtonToolbar,
  Popover,
  PopoverHeader,
  PopoverBody,
  Input,
  Card,
} from 'reactstrap';
import FilepondComponent from '~~/filepond-component/';
import getTransText from '~~/get-text/getTransText';
import { readProductApi, getProductFilesApi, getThumbnailsApi, editProductApi, publishProductApi } from '~/apiCalls/marketplaceApi';
import { apiCallBegan } from '~/store/api';
import {
  getProductInfo,
  getProductFiles,
  getThumbnails,
  marketplaceError,
  addedThumbnails,
  addedProductFiles,
} from '~/store/marketplace';
import ProductItems from '~~/catalog/ProductItems';
import {
  CardTitleWrap, CardTitle,
} from '~~/Card';
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupIcon,
  FormGroupLabel,
  FormHalfContainer,
} from '~~/form/FormElements';

const ProductEditForm = () => {
  const {
    userData,
  } = useSelector(state => state.user);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [popoverOpen2, setPopoverOpen2] = useState(false);
  const toggle = () => setPopoverOpen(!popoverOpen);
  const toggle2 = () => setPopoverOpen2(!popoverOpen2);
  const [token, setToken] = useState(null);
  const thumbnailsInstance = useRef(null);
  const productInstance = useRef(null);
  const { product_id } = useParams();
  const dispatch = useDispatch();

  const {
    currentProductInfo,
    thumbnails,
    productFiles,
  } = useSelector(state => state.marketplace);

  const thumbnailOnload = (response) => {
    const lists = JSON.parse(response);
    dispatch(addedThumbnails(lists));
    thumbnailsInstance.current.removeFiles();
    toggle();
  };

  const productOnload = (response) => {
    const lists = JSON.parse(response);
    dispatch(addedProductFiles(lists));
    productInstance.current.removeFiles();
    toggle2();
  };

  const onSubmit = async (values) => {
    values.status = "published";
    const res = await publishProductApi({ id: product_id, values });
  };

  const saveLater = async (values) => {
    const res = await editProductApi({ id: product_id, values });
  };

  const unpublishProduct = async (values) => {
    values.status = "inactive";
    const res = await editProductApi({ id: product_id, values });
  };


  useEffect(async () => {
    if (product_id) {
      dispatch(apiCallBegan({
        axiosCall: readProductApi,
        data: product_id,
        onSuccess: getProductInfo.type,
        onError: marketplaceError.type,
      }));
      dispatch(apiCallBegan({
        axiosCall: getProductFilesApi,
        data: product_id,
        onSuccess: getProductFiles.type,
        onError: marketplaceError.type,
      }));
      dispatch(apiCallBegan({
        axiosCall: getThumbnailsApi,
        data: product_id,
        onSuccess: getThumbnails.type,
        onError: marketplaceError.type,
      }));
    }
  }, [product_id]);

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        title: currentProductInfo?.title,
        description: currentProductInfo?.description,
        price: currentProductInfo?.price,
        discount: currentProductInfo?.discount,
      }}
      validate={values => {
        const errors = {};
        if (!values.title) {
          errors.request = getTransText("Please write your request");
        }
        if (!values.description) {
          errors.price = getTransText('Please fill in price');
        }
        if (isNaN(values.price)) {
          errors.price = getTransText('Must be a number');
        }
        if (thumbnails.length <= 0) {
          errors.thumbnails = getTransText('Required');
        }
        if (productFiles.length <= 0) {
          errors.productFiles = getTransText('Required');
        }
        return errors;
      }}
    >
      {({ handleSubmit, form, errors }) => (
        <FormContainer className="product-edit" onSubmit={handleSubmit}>
          <FormHalfContainer>
            {JSON.stringify(errors)}
            <FormGroup>
              <FormGroupLabel>Product Name</FormGroupLabel>
              <FormGroupField>
                <Field
                  name="title"
                  component="input"
                  type="text"
                >
                  {({ input, meta }) => (
                    <div className="form__form-group-field">
                      <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
                        <Input
                          {...input}
                          placeholder={getTransText("marketplace.product.title")}
                        />
                        {meta.error && meta.touched && <span className="form__form-group-error">{meta.error}</span>}
                      </div>
                    </div>
                  )}
                </Field>
              </FormGroupField>
            </FormGroup>
            <FormGroup>
              <FormGroupLabel>Description</FormGroupLabel>
              <FormGroupField>
                <Field
                  name="description"
                  component="textarea"
                  type="text"
                >
                  {({ input, meta }) => (
                    <div className="form__form-group-field">
                      <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
                        <ReactQuill
                          {...input}
                          formats={[]}
                          modules={{
                            toolbar: []
                          }}
                          type="textarea"
                          placeholder={getTransText("marketplace.product.description")}
                        // className="input-without-border-radius"
                        // theme="bubble"
                        />
                        {meta.error && meta.touched && <span className="form__form-group-error">{meta.error}</span>}
                      </div>
                    </div>
                  )}
                </Field>
              </FormGroupField>
            </FormGroup>
            <FormGroupPriceDiscount>
              <FormGroupPrice>
                <FormGroupLabel>Price</FormGroupLabel>
                <FormGroupField>
                  <FormGroupIcon>
                    <CurrencyUsdIcon />
                  </FormGroupIcon>
                  <Field
                    name="price"
                    component="input"
                    type="text"
                  >
                    {({ input, meta }) => (
                      <div className="form__form-group-field">
                        <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
                          <Input
                            {...input}
                            placeholder={getTransText("marketplace.product.price")}
                          />
                          {meta.error && meta.touched && <span className="form__form-group-error">{meta.error}</span>}
                        </div>
                      </div>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroupPrice>
              <FormGroup>
                <FormGroupLabel>Discount</FormGroupLabel>
                <FormGroupField>
                  <FormGroupIcon>
                    <TagIcon />
                  </FormGroupIcon>
                  <Field
                    name="discount"
                    component="input"
                    type="text"
                  >
                    {({ input, meta }) => (
                      <div className="form__form-group-field">
                        <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
                          <Input
                            {...input}
                            placeholder={getTransText("marketplace.product.discount")}
                          />
                          {meta.error && meta.touched && <span className="form__form-group-error">{meta.error}</span>}
                        </div>
                      </div>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>
            </FormGroupPriceDiscount>
            <CardTitleWrap>
              <CardTitle>Thumbnails</CardTitle>
            </CardTitleWrap>
            <FormContainer horizontal as="div">
              <FormGroup>
                <>
                  <Button id="Popover1" type="button" className="btn control-btn btn-primary " label="Upload file"><i className="las la-upload"></i></Button>
                  <Popover trigger="legacy" placement="bottom" isOpen={popoverOpen} target="Popover1" toggle={toggle}>
                    <PopoverBody>
                      <FilepondComponent filepurpose="marketplace-thumbnails" onload={thumbnailOnload} ref={thumbnailsInstance} description={product_id} />
                    </PopoverBody>
                  </Popover>
                  {JSON.stringify(thumbnails)}
                </>
                {/* <Field
                  name="thumbnails"
                >
                  {({ input, meta }) => (
                    <>
                      <Button id="Popover1" type="button" className="btn control-btn btn-primary " label="Upload file"><i className="las la-upload"></i></Button>
                      <Popover trigger="legacy" placement="bottom" isOpen={popoverOpen} target="Popover1" toggle={toggle}>
                        <PopoverBody>
                          <FilepondComponent filepurpose="marketplace-thumbnails" onload={thumbnailOnload} ref={thumbnailsInstance} description={product_id} />
                        </PopoverBody>
                      </Popover>
                      {JSON.stringify(thumbnails)}
                      {meta.error && <span className="form__form-group-error">{meta.error}</span>}
                    </>
                  )}
                </Field> */}
              </FormGroup>
            </FormContainer>
            <CardTitleWrap>
              <CardTitle>Secret Content</CardTitle>
            </CardTitleWrap>
            <FormContainer horizontal as="div">
              <FormGroup>
                <Field
                  name="productFiles"
                >
                  {({ input, meta }) => (
                    <>
                      <Button id="Popover2" type="button" className="btn control-btn btn-primary " onClick={() => console.log("ss")} label="Upload file"><i className="las la-upload"></i></Button>
                      <Popover trigger="legacy" placement="bottom" isOpen={popoverOpen2} target="Popover2" toggle={toggle2}>
                        <PopoverBody>
                          <FilepondComponent filepurpose="marketplace-products" onload={productOnload} ref={productInstance} description={product_id} />
                        </PopoverBody>
                      </Popover>
                      {JSON.stringify(productFiles)}
                      {meta.error && <span className="form__form-group-error">{meta.error}</span>}
                    </>
                  )}
                </Field>
              </FormGroup>
            </FormContainer>
          </FormHalfContainer>
          <FormSpy subscription={{ values: true }}>
            {({ values }) => (
              <>
                <FormHalfContainer>
                  <FormGroup>
                    <FormGroupLabel>Upload photo</FormGroupLabel>
                    <FormGroupField>
                      <Container>
                        <Row>
                          <Col md={12} lg={12}>
                            <ProductItems items={[values]} />
                          </Col>
                        </Row>
                      </Container>
                    </FormGroupField>
                  </FormGroup>
                </FormHalfContainer>
                <FormButtonToolbar>
                  <>
                    <Button variant="primary" type="button" onClick={() => saveLater(values)}>Save</Button>
                  </>
                  {currentProductInfo?.status === "published"
                    ? <Button variant="secondary" type="button" onClick={() => unpublishProduct(values)}>Unpublish</Button>
                    : <Button variant="secondary" type="submit">Publish</Button>}

                </FormButtonToolbar>
              </>
            )}
          </FormSpy>

        </FormContainer>
      )}
    </Form>
  );
};


export default ProductEditForm;

// region STYLES

const FormGroupPrice = styled(FormGroup)`
  margin-right: 20px;
  
  @media screen and (max-width: 767px) {
    margin-right: 0;
  }
`;

const FormGroupPriceDiscount = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 20px;

  & > div {
    margin-bottom: 0;
  }

  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
  }
`;

const FormGroupId = styled(FormGroup)`
  min-width: 100px;
  width: 40%;
  margin-right: 20px;

  @media screen and (max-width: 767px) {
    margin-right: 0;
    width: 100%;
  }
`;

const FormGroupIdCategory = styled.div`
  width: 100%;
  display: flex;

  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
  }
`;

// endregion
