// action
import { createAction, createReducer, createSelector } from "@reduxjs/toolkit";
import Cookies from 'js-cookie';

export const getProductList = createAction("marketplace/getProductList");
export const getProductInfo = createAction("marketplace/getProductInfo");
export const getProductFiles = createAction("marketplace/getProductFiles");
export const addedProductFiles = createAction("marketplace/addedProductFiles");
export const getThumbnails = createAction("marketplace/getThumbnails");
export const addedThumbnails = createAction("marketplace/addedThumbnails");
export const marketplaceError = createAction("marketplace/marketplaceError");



// reducer
const initialState = {
  productList: [],
  currentProductInfo: {},
  thumbnails: [],
  productFiles: [],
  error: "",
};

const marketplaceReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getProductList.type, (state, action) => ({
      ...state,
      productList: action.payload,
    }))
    .addCase(getProductInfo.type, (state, action) => ({
      ...state,
      currentProductInfo: action.payload,
    }))
    .addCase(getProductFiles.type, (state, action) => ({
      ...state,
      productFiles: action.payload,
    }))
    .addCase(addedProductFiles.type, (state, action) => {
      const exists = state.productFiles.some((product) => product.id === action.payload.id);
      if (!exists) {
        state.productFiles.push(action.payload);
      }
    })
    .addCase(getThumbnails.type, (state, action) => ({
      ...state,
      thumbnails: action.payload,
    }))
    .addCase(addedThumbnails.type, (state, action) => {
      const exists = state.thumbnails.some((thumbnail) => thumbnail.id === action.payload.id);
      if (!exists) {
        state.thumbnails.push(action.payload);
      }
    })
    .addCase(marketplaceError.type, (state, action) => ({
      ...state,
      error: action.payload,
    }));
});

export default marketplaceReducer;
// export const getAuthStore = createSelector();
