import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col } from 'reactstrap';
import styled from 'styled-components';
import DeleteForeverIcon from 'mdi-react/DeleteForeverIcon';
import {
  Card, CardBody, CardTitleWrap, CardTitle,
} from '~~/Card';
import { apiCallBegan } from '~/store/api';
import { getCartInfoListApi } from '~/apiCalls/cartApi';
import { cartError, getCartInfoList, removedFromCart } from '~/store/cart';
import { colorAdditional, colorRedHover } from '~/utils/palette';
import { Table } from '~~/TableElements';
import CartPurchase from './CartPurchase';
import { addTokenToURL } from '~/apiCalls/authApi';


const CartCard = () => {
  const dispatch = useDispatch();
  const { cart, cartInfoList } = useSelector(state => state.cart);

  useEffect(() => {
    if (cart.length > 0) {
      dispatch(
        apiCallBegan({
          axiosCall: getCartInfoListApi,
          data: cart,
          onSuccess: getCartInfoList.type,
          onError: cartError.type,
        })
      );
    }
  }, [cart]);

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <CardTitleWrap>
            <CardTitle>Cart</CardTitle>
          </CardTitleWrap>
          <CartTable bordered responsive>
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                {/* <th>Quantity</th> */}
                <th>Price</th>
                {/* <th>Tax</th> */}
                {/* <th>Total</th> */}
                <th aria-label="cart table" />
              </tr>
            </thead>
            <tbody>
              {cartInfoList.map((ct, i) => (
                <tr key={`index_${ct.title}`}>
                  <td>{i + 1}</td>
                  <td>
                    <CartPreviewImageWrap>
                      <img src={`${process.env.REACT_APP_API_URL}assets/${ct.thumbnail_list[0].product_thumbnail_id}?fit=cover&width=150&height=150`} alt="product_preview" />
                    </CartPreviewImageWrap>
                    <span>{ct.title}</span>
                  </td>
                  {/* <td>{ct.quantity}</td> */}
                  <td>${ct.price}</td>
                  {/* <td>${ct.tax.toFixed(2)}</td> */}
                  {/* <td>${ct.total.toFixed(2)}</td> */}
                  <td>
                    <CartTableButton type="button" onClick={() => dispatch(removedFromCart(ct.id))}>
                      <DeleteForeverIcon /> Remove
                    </CartTableButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </CartTable>
          <CartSubTotal>Sub-total: $328.00</CartSubTotal>
          <CartPurchase onSubmit />
        </CardBody>
      </Card>
    </Col>
  );
};

export default CartCard;

// region STYLES

const CartPreviewImageWrap = styled.span`
  width: 50px;
  height: 45px;
  border: 1px solid #f0f0f0;
  display: inline-block;
  overflow: hidden;
  text-align: center;
  padding: 5px;
  position: absolute;
  top: 8px;

  & + span {
    padding-left: 60px;
    display: block;
    min-width: 400px;
  }

  img {
    height: 100%;
    width: auto;
    max-width: 100%;
  }
`;

const CartTableButton = styled.button`
  background: transparent;
  border: none;
  line-height: 14px;
  padding-left: 20px;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  position: relative;
  color: ${colorAdditional};
  cursor: pointer;
  transition: all 0.3s;

  svg {
    height: 16px;
    width: 16px;
    position: absolute;
    top: -2px;
    left: 0;
    fill: ${colorAdditional};
    transition: all 0.3s;
  }

  &:hover {
    color: ${colorRedHover};

    svg {
      fill: ${colorRedHover};
    }
  }
`;

const CartTable = styled(Table)`
  
  tbody td {
    padding: 20px 10px;
    position: relative;
  }
`;

const CartSubTotal = styled.h5`
  text-align: left;
  margin-top: 20px;
  font-weight: 700;
`;

// endregion
